<template>
    <div class="resetPassword xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>个人中心</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personal-left">
                    <hd-img></hd-img>
                </div>
                <div class="personal-right">
                    <div class="personal-right-tit patientheard">
                        <div> <i class="iconfont">&#xe62c;</i><span>视频合集<em>管理</em></span></div>
                        <div style="color:#1FB7A6;font-weight: bold;margin-left: 15px;cursor: pointer;" @click="goadd"><i
                                class="el-icon-plus"></i>发布</div>
                    </div>
                    <div class="patientBox">
                        <div class="patient" v-for="(item, index) in videoList" :key="index">
                            <div class="pList">
                                <div class="pListLeft">
                                    <div class="videobox">
                                        <img :src="item.collectionUrl" alt="" srcset="">
                                        <!-- <div class="videoicon">01:20:10</div> -->
                                    </div>
                                    <div>
                                        <h2 style="color:#333333">{{ item.name }}</h2>
                                        <div style="padding: 20px 0">
                                            <div style="width: 67px; height: 26px">
                                                <img style="width:100%; height:100%" src="@/assets/img/videoIcon.png"
                                                    alt="">
                                            </div>
                                        </div>
                                        <div>
                                            <div style="color:#808080; display:flex; justify-content: space-between;">
                                                <div style="display:flex">
                                                    <div style="margin-right:10px">权限：{{ item.limits == 0 ? '公开发布' : '私密发布'
                                                    }}</div>
                                                    <div>分类：{{ item.category.name }}</div>
                                                </div>
                                                <div style=" margin-left: 10px;">
                                                    {{ item.addTime }}
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="right-btn flex-center flex-wrap">
                                    <el-tag @click="govideoCollect(item)">视频</el-tag>
                                    <el-tag type="success" @click="editcollect(item)">编辑</el-tag>
                                    <el-tag type="danger" @click="godelete(item)">删除</el-tag>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
</div>
</template>
<script>
import ftCom from '../../components/footer'
import nhCom from "../../components/navheader";
import hdImg from "../../components/headerImg";
// import {patientMain} from "../../components/patienTmanagement/patienTmanagement";
import { videoMain } from "../../components/video/video";
export default {
    name: "resetPassword",
    components: {
        ftCom,
        nhCom,
        hdImg
    },
    data() {
        // var patient= new patientMain(this.TokenClient, this.Services.Authorization)
        var video = new videoMain(this.TokenClient, this.Services.Statics)
        return {
            // patientDomain:patient,
            videoDomain: video,
            labelPosition: 'right',
            resetPassword: {
                oldPwd: '',
                newPwd: '',
                confirmPwd: ''
            },
            uploader: null,
            file: '',
            patientType: 1,// 固定值
            organitionId: 0,// 固定值
            keyWord: ' ',
            pageIndex: 1,
            userInfo: {},
            patientList: [],
            visible: false,
            videoList: []
        };
    },
    mounted() {
        this.getList()
    },
    methods: {
        godelete(e) {
            this.$confirm('是否删除此合集?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.videoDomain.DeleteCollection(e.id, (data) => {
                    this.getList()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });

                }, function (error) {
                    // _this.$message.error('修改失败！旧密码不正确，请重新输入。');
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        goraddecord(e) {
            this.$router.push({
                path: '/patienTmanagement/addRecord',
                query: {
                    patientId: e.id
                }
            })
        },
        govideoCollect(e) {
            this.$router.push({
                path: '/video/collectVideoList',
                query: {
                    videoId: e.id
                }
            })
        },
        goadd() {
            this.$router.push('/video/addcollect')
        },
        editcollect(e) {
            this.$router.push({
                path: '/video/addcollect',
                query: {
                    videoId: e.id
                }
            })
        },
        getList() {
            var _this = this;
            _this.userInfo = this.$store.state.localData.userInfo
            // console.log(localStorage.getItem('userInfo'))
            let params = '0/1/' + _this.userInfo.id + '/0/-999/ /' + _this.pageIndex
            _this.videoDomain.MyselfCollection(params, function (data) {
                _this.videoList = data.data.results
                for (var i = 0; i < _this.videoList.length; i++) {
                    var f = _this.videoList[i].addTime.split('T')
                    _this.videoList[i].addTime = f[0] + ' ' + f[1].slice(0, 8)
                }

            }, function (error) {
                console.log(error);
            })
        }
    }
}
</script>

<style scoped>
@import "../../assets/css/footer.css";

.banner {
    width: 100%;
    height: 160px;
    background: url("../../assets/img/news_banner.jpg") center no-repeat;
}

.videobox {
    position: relative;
    width: 235px;
    height: 130px;
    border-radius: 8px;
    margin-right: 25px;
}

.videobox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.videoicon {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #000000;
    opacity: 0.6;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    /* padding: 5px 0; */
}

.patientheard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 21px 0 15px 0;
    border-bottom: 1px dashed #CCCCCC;
}

.patientright {
    display: flex;
    align-items: center;
}

.patientBox {
    padding: 21px 42px;
    color: #808080
}

.patient {
    padding: 21px 0 15px 0;
    border-bottom: 1px dashed #CCCCCC;
}

.pList {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.right-btn{
    width:80px;
}
.right-btn /deep/ .el-tag{
    margin-bottom: 10px;
    cursor: pointer;
}
.pListLeft {
    display: flex;
    align-items: center;
}

.banner-wrap {
    position: relative;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}

.banner-text {
    float: left;
}

.banner-text h3 {
    padding-top: 35px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 14px;
    color: #ffffff;
    margin-top: 20px;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

/**/
.personal-content {
    background: #f7f7f7;
    padding: 40px 0;
}

.personal-box {
    width: 1200px;
    margin: 0 auto;
}

.personal-left {
    float: left;
}

.personal-right {
    float: right;
    width: 900px;
    background: #FFFFFF;
    box-sizing: border-box;
}

.personal-right-tit {
    border-bottom: 1px solid #e5e5e5;
    padding: 20px;
}

.personal-right-tit .iconfont {
    color: #04a290;
    font-size: 24px;
    margin-right: 5px;
}

.personal-right-tit span {
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    color: #333333;
}

.personal-right-tit span em {
    font-style: normal;
    color: #04a290;
}

.personal-right>form {
    padding: 30px 15px;
    width: 460px;
}
</style>
<style>
.personal-center {
    display: none;
}
</style>
